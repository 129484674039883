<script context="module">
  import Pagination, {
    setLabels as _setPaginationLabels,
  } from './Pagination.svelte';
  import Row from './Row.svelte';
  import Search, { setLabels as _setSearchLabels } from './Search.svelte';
  import Sort, { setLabels as _setSortLabels } from './Sort.svelte';
  export { Pagination, Row, Search, Sort };

  let globalLabels;

  export function setTableLabels(labels) {
    globalLabels = labels;
  }

  export const setPaginationLabels = _setPaginationLabels;
  export const setSearchLabels = _setSearchLabels;
  export const setSortLabels = _setSortLabels;
</script>

<script>
  import { createEventDispatcher, setContext } from 'svelte';
  const dispatch = createEventDispatcher();

  // export let loading = false;
  export let page = 0;
  export let pageIndex = 0;
  export let pageSize = 10;
  export let rows;
  export let serverSide = false;
  export let labels = {
    empty: 'No Projects found.',
    loading: 'Loading data',
    ...globalLabels,
  };

  let buttons = [-2, -1, 0, 1, 2];
  let pageCount = 0;

  $: filteredRows = rows;
  $: visibleRows = filteredRows.slice(pageIndex, pageIndex + pageSize);

  setContext('state', {
    getState: () => ({
      page,
      pageIndex,
      pageSize,
      rows,
      filteredRows,
    }),
    setPage: (_page, _pageIndex) => {
      page = _page;
      pageIndex = _pageIndex;
    },
    setPageSize: _pageSize => {
      pageSize = _pageSize;
    },
    setRows: _rows => (filteredRows = _rows),
  });

  function onPageChange(event) {
    dispatch('pageChange', event.detail);
  }

  function onSearch(event) {
    dispatch('search', event.detail);
  }
</script>

<slot name="top">
  <div class="slot-top">
    <svelte:component this={Search} on:search={onSearch} />
  </div>
</slot>

<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
  <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
      <table class="min-w-full divide-y divide-gray-200">
        <slot name="head" />
        {#if visibleRows.length === 0}
          <tbody class="bg-white divide-y divide-gray-200">
            <tr>
              <td class="center" colspan="100%">
                <div class="text-gray-400 p-20 flex justify-center">
                  {@html labels.empty}
                </div>
              </td>
            </tr>
          </tbody>
        {:else}
          <slot rows={visibleRows} />
        {/if}
        <slot name="foot" />
      </table>
    </div>
  </div>
</div>

<slot name="bottom">
  <div class="slot-bottom">
    <svelte:component
      this={Pagination}
      {page}
      {pageSize}
      {serverSide}
      count={filteredRows.length - 1}
      on:pageChange={onPageChange}
    />
  </div>
</slot>
