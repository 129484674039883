<script context="module">
  let globalLabels;

  export function setLabels(labels) {
    globalLabels = labels;
  }
</script>

<script>
  import { createEventDispatcher, getContext } from 'svelte';

  export let archivedControl = true;

  const dispatch = createEventDispatcher();
  const stateContext = getContext('state');

  export let filter = (row, text, index) => {
    text = text.toLowerCase();

    for (let i in row)
      if (row[i].toString().toLowerCase().indexOf(text) > -1) return true;

    return false;
  };

  export let index = -1;
  export let text = '';

  export let labels = {
    placeholder: 'Search by Name, Label, etc',
    ...globalLabels,
  };

  let caseSensitive = false;
  let showArchived = false;

  let timer;
  const debounce = f => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      f();
    }, 200);
  };

  async function onSearch(event) {
    const state = stateContext.getState();
    const detail = {
      originalEvent: event,
      filter,
      index,
      text,
      page: state.page,
      pageIndex: state.pageIndex,
      pageSize: state.pageSize,
      rows: state.filteredRows,
      caseSensitive: caseSensitive,
      showArchived: showArchived,
    };
    dispatch('search', detail);
  }
</script>

<div class="flex items-center justify-between mb-4">
  <div class="p-1 bg-blue-400 rounded-lg searchbar-container">
    <div class="relative inline-block" style="width: 360px;">
      <input
        type="search"
        title={labels.placeholder}
        placeholder={labels.placeholder}
        bind:value={text}
        on:input={() => debounce(onSearch)}
        class="w-full py-2 pl-10 pr-4 font-medium text-gray-600 placeholder-gray-500 placeholder-opacity-50 border-0 rounded-lg shadow focus:outline-none focus:ring focus:border-blue-300"
      />
      <div class="absolute top-0 left-0 inline-flex items-center p-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-6 h-6 text-gray-400"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <rect x="0" y="0" width="24" height="24" stroke="none" />
          <circle cx="10" cy="10" r="7" />
          <line x1="21" y1="21" x2="15" y2="15" />
        </svg>
      </div>
    </div>
    <div class="inline-block text-white pl-1 capitalize align-top pt-1.5">
      <input
        class="w-6 h-6 mr-0.5 ml-2 cursor-pointer border-none rounded-lg"
        id="case-sensitive-check"
        type="checkbox"
        bind:checked={caseSensitive}
        on:change={onSearch}
      />
      <label for="case-sensitive-check" class="align-middle cursor-pointer"
        >case sensitive</label
      >
      {#if archivedControl}
        <input
          class="w-6 h-6 mr-0.5 ml-2 cursor-pointer border-none rounded-lg"
          id="case-sensitive-check"
          type="checkbox"
          bind:checked={showArchived}
          on:change={onSearch}
        />
        <label for="include-archived-check" class="align-middle cursor-pointer"
          >Show Archived Only</label
        >{/if}
    </div>
  </div>
  <div>
    <div class="flex rounded-lg shadow">
      <div class="relative" />
    </div>
  </div>
</div>

<style>
  .searchbar-container {
    width: 734px;
  }
</style>
