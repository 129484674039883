import Projects from './online-resource-projects.svelte';

const projects = new Projects({
  target: document.getElementById('projects'),
  props: {
    appBaseUrl: document.getElementById('projects').getAttribute('appBaseUrl'),
    userIsAdmin: document
      .getElementById('projects')
      .getAttribute('userIsAdmin'),
  },
});
