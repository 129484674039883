<script>
  //Row component is optional and only serves to render odd/even row, you can use <tr> instead.
  //Sort component is optional
  import { onMount } from 'svelte';
  import Table, { Pagination, Row, Search, Sort } from '../table/Table.svelte';
  import RowDetails from './row-details.svelte';

  let rows = [];
  let page = 0; //first page
  let pageIndex = 0; //first row
  let pageSize = 20; //optional, 10 by default

  let caseSensitive = false;
  let showArchived = false;

  let loading = true;
  let rowsCount = 0;
  let text;
  let sorting /*  = {} */;

  export let appBaseUrl;
  export let userIsAdmin;

  onMount(async () => {
    await load(page);
  });

  async function load(_page, _pageSize) {
    loading = true;
    //    const data = await getData(_page, pageSize, text, sorting);
    let params = {
      caseSensitive: _page.caseSensitive || false,
      showArchived: _page.showArchived || false,
    };
    if (text) params.search = text;

    params.limit = _pageSize || pageSize;

    if (_page) params.page = _page + 1;

    //color:DESC
    if (sorting /*  && sorting.dir */)
      params.sortBy = `${sorting.key}:${sorting.dir.toUpperCase()}`;

    const req = await fetch(
      `${window.location.href}/json?${new URLSearchParams(params)}`,
    );
    const data = await req.json();

    rows = data.projects.data;
    rowsCount = data.projects.meta.totalItems;
    loading = false;
  }

  function onCellClick(row) {
    alert(JSON.stringify(row));
  }

  function onPageChange(event) {
    load(event.detail.page, event.detail.pageSize);
    page = event.detail.page;
    pageSize = event.detail.pageSize;
  }

  async function onSearch(event) {
    text = event.detail.text || null;
    caseSensitive = event.detail.caseSensitive;
    showArchived = event.detail.showArchived;

    await load({ ...page, caseSensitive, showArchived });
    page = 0;
  }

  async function onSort(event) {
    sorting = { dir: event.detail.dir, key: event.detail.key };
    await load(page);
  }

  const dateToInternalDate = x => {
    const d = new Date(x);
    return `${String(d.getDate()).padStart(2, '0')}/${String(
      d.getMonth() + 1,
    ).padStart(2, '0')}/${d.getFullYear()}`;
  };

  async function confirmDelete(projectId) {
    if (
      confirm(`
    Are you absolutely sure you want to proceed? \n
    This is a PERMANENT action that will:
    - Delete this Online Resource project, its FORKED sub-projects, their versions and their files permanently    

    Please click Ok only if you understand this is an unrecoverable action.
    `)
    ) {
      await fetch(
        `${appBaseUrl}/online-resources/projects/${projectId}/delete`,
      );
      await load({ ...page, caseSensitive, showArchived });
    }
  }

  async function confirmArchive(projectId) {
    if (confirm('Confirm archiving this project?')) {
      await fetch(
        `${appBaseUrl}/online-resources/projects/${projectId}/archive`,
      );
      await load({ ...page, caseSensitive, showArchived });
    }
  }

  async function confirmRestore(projectId) {
    if (confirm('Confirm restoring this project?')) {
      await fetch(
        `${appBaseUrl}/online-resources/projects/${projectId}/restore`,
      );
      await load({ ...page, caseSensitive, showArchived });
    }
  }
</script>

<Table {loading} {rows} {pageIndex} {pageSize} let:rows={rows2}>
  <div slot="top">
    <Search on:search={onSearch} />
  </div>
  <thead slot="head" class="bg-gray-50">
    <tr>
      <th
        scope="col"
        class="relative px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
      >
        <Sort
          key="multimediaLearningResourceTitle"
          label="Name"
          on:sort={onSort}
          reset={sorting?.key !== 'multimediaLearningResourceTitle'}
        />
      </th>
      <th
        scope="col"
        class="relative px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
      >
        <Sort
          key="identifier"
          label="Identifier"
          on:sort={onSort}
          reset={sorting?.key !== 'identifier'}
        />
      </th>
      <th
        scope="col"
        class="relative px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
      >
        <span>Type</span>
      </th>
      <th
        scope="col"
        class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase relative"
      >
        <Sort
          key="labels"
          label="Labels"
          on:sort={onSort}
          reset={sorting?.key !== 'labels'}
        />
      </th>
      <th
        scope="col"
        class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase relative"
      >
        <Sort
          key="createdAt"
          label="Creation Date"
          on:sort={onSort}
          reset={sorting?.key !== 'createdAt'}
        />
      </th>
      <th scope="col" class="relative px-6 py-3">
        <span class="sr-only">Actions</span>
      </th>
    </tr>
  </thead>
  <tbody class="text-sm bg-white divide-y divide-gray-200">
    {#each rows2 as row, index (row)}
      <Row {index}>
        <td
          data-label="Name"
          class="px-6 py-4 cursor-pointer"
          on:click={() => {
            rows2[index].expanded = !rows2[index].expanded;
          }}
        >
          <div class="flex">
            <div>
              {#if rows2[index].expanded}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 mr-2 -ml-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              {:else}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 mr-2 -ml-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              {/if}
            </div>
            <div>
              <div class="text-sm font-medium text-gray-900">
                {row.multimediaLearningResourceTitle}
              </div>

              <div class="text-sm text-gray-500">
                {#if row.children || row.parent}
                  <span class="inline-flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                      />
                    </svg>
                    {#if row.children}
                      {row.children} Sub-Project(s)
                    {:else if row.parent}
                      Created from {row.parent.multimediaLearningResourceTitle}
                    {/if}
                  </span>
                {/if}
              </div>
            </div>
          </div>
        </td>

        <td data-label="Identifier" class="px-6 py-4 whitespace-nowrap">
          <div class="text-sm font-medium text-gray-900">
            {#if row.identifier}{row.identifier}{/if}
          </div>
        </td>

        <td data-label="Identifier" class="px-6 py-4 whitespace-nowrap">
          <div class="text-sm font-medium text-gray-900">
            {#if row.resourceType}
              {row.resourceType.name}
            {/if}
          </div>
        </td>

        <td data-label="Label" class="px-6 py-4">
          {#each row.labels as label}
            <div
              class="inline-flex px-2 mr-1 text-sm font-medium leading-5 text-blue-800 bg-blue-100 rounded-lg"
            >
              {label}
            </div>
          {/each}
        </td>
        <td
          data-label="Date"
          class="px-6 py-4 text-sm text-gray-600 whitespace-nowrap"
          >{dateToInternalDate(row.createdAt)}</td
        >
        <td
          data-label="Actions"
          class="px-6 py-4 text-sm font-medium text-right whitespace-nowrap"
        >
          {#if !row.deletedAt}
            {#if !row.parent}
              <a
                href="{appBaseUrl}/online-resources/projects/new?from={row.id}"
                class="mx-3 text-blue-600 hover:text-blue-900"
                on:click={event => {
                  event.stopPropagation();
                }}
              >
                Fork
              </a>
            {/if}
            <a
              href="{appBaseUrl}/online-resources/projects/{row.id}/versions"
              class="mx-3 text-blue-600 hover:text-blue-900 event"
              on:click={event => {
                event.stopPropagation();
              }}>View</a
            >
            <a
              href="{appBaseUrl}/online-resources/projects/{row.id}/edit"
              class="mx-3 text-blue-600 hover:text-blue-900"
              on:click={event => {
                event.stopPropagation();
              }}>Edit</a
            >
            <button
              on:click={confirmArchive(row.id)}
              class="mx-3 font-semibold text-yellow-600 hover:text-yellow-900"
              >Archive</button
            >
          {:else}
            {#if userIsAdmin == 'true'}
              <button
                on:click={confirmDelete(row.id)}
                class="mx-3 font-semibold text-red-600 hover:text-yellow-900 pbac-op-asset_manager.remove_asset-[]"
                >Delete forever</button
              >
            {/if}
            <button
              on:click={confirmRestore(row.id)}
              class="mx-3 font-semibold text-yellow-600 hover:text-yellow-900"
              >Restore</button
            >
          {/if}
        </td>
      </Row>
      <RowDetails {row} />
    {/each}
  </tbody>
  <div slot="bottom">
    <Pagination
      {page}
      {pageSize}
      count={rowsCount}
      serverSide={true}
      on:pageChange={onPageChange}
    />
  </div>
</Table>
