<script context="module">
  let globalLabels;

  export function setLabels(labels) {
    globalLabels = labels;
  }
</script>

<script>
  import { createEventDispatcher, getContext } from 'svelte';
  const dispatch = createEventDispatcher();
  const stateContext = getContext('state');

  export let buttons = [-2, -1, 0, 1, 2];
  export let count;
  export let page = 0;
  export let pageSize;
  export let serverSide = true;

  export let labels = {
    first: 'First',
    last: 'Last',
    next: 'Next',
    previous: 'Previous',
    ...globalLabels,
  };

  $: pageCount = Math.ceil(count / pageSize);

  function onChange(event, page, newPageSize) {
    const state = stateContext.getState();

    const detail = {
      originalEvent: event,
      page,
      pageIndex: serverSide ? 0 : page * state.pageSize,
      pageSize: newPageSize ? newPageSize : state.pageSize,
    };

    dispatch('pageChange', detail);

    if (detail.preventDefault !== true) {
      if (newPageSize) stateContext.setPageSize(newPageSize);

      stateContext.setPage(detail.page, detail.pageIndex);
    }
  }
</script>

{#if pageCount}
  <div
    class="flex items-center justify-center px-4 py-4 border-gray-200 sm:px-0"
  >
    <div class="flex justify-between flex-1 sm:hidden">
      <button
        disabled={page === 0}
        on:click={e => onChange(e, page - 1)}
        class="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:text-gray-500"
      >
        {labels.previous}
      </button>
      <button
        disabled={page > pageCount - 1}
        on:click={e => onChange(e, page + 1)}
        class="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:text-gray-500"
      >
        {labels.next}
      </button>
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:justify-between">
      <div class="flex flex-row items-center justify-center space-x-2">
        <nav
          class="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
          aria-label="Pagination"
        >
          <button
            on:click={e => onChange(e, page, 5)}
            class="relative inline-flex items-center px-4 py-2 text-sm font-medium bg-white border border-gray-300 rounded-l-md hover:bg-gray-50"
            class:pagesize-button-active={pageSize === 5}
          >
            5
          </button>
          <button
            on:click={e => onChange(e, page, 10)}
            class="relative inline-flex items-center px-4 py-2 text-sm font-medium bg-white border border-gray-300 hover:bg-gray-50"
            class:pagesize-button-active={pageSize === 10}
          >
            10
          </button>
          <button
            on:click={e => onChange(e, page, 20)}
            class="relative inline-flex items-center px-4 py-2 text-sm font-medium bg-white border border-gray-300 hover:bg-gray-50"
            class:pagesize-button-active={pageSize === 20}
          >
            20
          </button>
          <button
            on:click={e => onChange(e, page, 50)}
            class="relative inline-flex items-center px-4 py-2 text-sm font-medium bg-white border border-gray-300 hover:bg-gray-50"
            class:pagesize-button-active={pageSize === 50}
          >
            50
          </button>
          <button
            on:click={e => onChange(e, page, 100)}
            class="relative inline-flex items-center px-4 py-2 text-sm font-medium bg-white border border-gray-300 rounded-r-md hover:bg-gray-50"
            class:pagesize-button-active={pageSize === 100}
          >
            100
          </button>
        </nav>
      </div>
      <div>
        <nav
          class="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
          aria-label="Pagination"
        >
          <button
            disabled={page === 0}
            on:click={e => onChange(e, page - 1)}
            class="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50"
          >
            <span class="sr-only">{labels.previous}</span>
            <!-- Heroicon name: solid/chevron-left -->
            <svg
              class="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </button>

          {#each buttons as button}
            {#if page + button >= 0 && page + button <= pageCount - 1}
              <button
                on:click={e => onChange(e, page + button)}
                class="relative inline-flex items-center px-4 py-2 text-sm font-medium bg-white border border-gray-300 hover:bg-gray-50"
                class:pagination-button-active={page === page + button}
              >
                {page + button + 1}
              </button>
            {/if}
          {/each}

          <button
            disabled={page > pageCount - 2}
            on:click={e => onChange(e, page + 1)}
            class="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50"
          >
            <span class="sr-only">{labels.next}</span>
            <!-- Heroicon name: solid/chevron-right -->
            <svg
              class="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </button>
        </nav>
      </div>
    </div>
  </div>
{/if}
