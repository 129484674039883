<script>
  export let row;
</script>

<tr class:hidden={!row.expanded}>
  <td colspan="6" class="space-y-6 md:space-y-0">
    <div class="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="text-sm font-medium text-gray-500">Name</dt>
      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        {#if row.multimediaLearningResourceTitle}{row.multimediaLearningResourceTitle}{/if}
      </dd>
    </div>
    <div class="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="text-sm font-medium text-gray-500">Identifier</dt>
      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        {#if row.identifier}{row.identifier}{/if}
      </dd>
    </div>
    <div class="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="text-sm font-medium text-gray-500">Year</dt>
      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        {#if row.year}{row.year}{/if}
      </dd>
    </div>
    <div class="bg-gray-50 px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="text-sm font-medium text-gray-500">Author</dt>
      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        {#if row.author}{row.author}{/if}
      </dd>
    </div>
    <div
      class="bg-gray-50 px-4 pt-3 pb-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
    >
      <dt class="text-sm font-medium text-gray-500">Instructional Designer</dt>
      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        {#if row.instructionalDesigner}{row.instructionalDesigner}{/if}
      </dd>
    </div>
    <div
      class="bg-gray-50 px-4 pt-3 pb-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
    >
      <dt class="text-sm font-medium text-gray-500">Initiative</dt>
      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        {#if row.initiative?.initiativeTitle}{row.initiative
            ?.initiativeTitle}{/if}
      </dd>
    </div>
  </td>
</tr>
